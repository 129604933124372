import React, { createElement, useState } from "react";
import dynamic from "next/dynamic";
import Image from "@/components/Image/image";
import classnames from "classnames";
import ReactPlayer from "react-player/lazy";
import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay";
import styles from "./hero-video.module.scss";
import Components from "@/components/components";
import { HeroVideoStoryblok, ImageStoryblok } from "@/types/component-types-sb";

const Modal = dynamic(() =>
  import("@ef-global/web-ui-react/lib/components/Modal").then(
    (mod) => mod.Modal
  )
);

interface Props {
  blok: HeroVideoStoryblok;
  className?: string;
}

const HeroVideo = (props: Props) => {
  const {
    blok: {
      url,
      poster_desktop,
      poster_mobile,
      aspect_mobile,
      aspect_desktop,
      title,
      autoplay,
      rounded_corners,
      center_button,
      small_video_play_button,
    },
    className,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const handleClickOpen = () => {
    if (!url) return;
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const classes = classnames(
    styles.heroVideo,
    rounded_corners ? styles.rounded : "",
    autoplay ? styles.autoplay : "",
    className
  );

  return (
    <>
      <div onClick={handleClickOpen} className={classes}>
        {poster_mobile ? (
          <div className={`${styles.videoWrap} u-show-s-only`}>
            <Poster
              alt={poster_mobile.alt}
              filename={poster_mobile.filename}
              aspectMobile={aspect_mobile}
              title={title}
              autoplay={autoplay}
            />
          </div>
        ) : null}
        {poster_desktop ? (
          <div className={`${styles.videoWrap} u-show-m-up`}>
            <Poster
              alt={poster_desktop.alt}
              filename={poster_desktop.filename}
              aspectDesktop={aspect_desktop}
              title={title}
              autoplay={autoplay}
              centerButton={center_button}
              smallVideoPlayButton={small_video_play_button}
            />
          </div>
        ) : null}
      </div>
      <Modal
        className={classnames("ef-modal", "-l", styles.heroModal)}
        isOpen={openModal}
        onDismiss={handleClose}
        size={"l"}
        overlayClassName={{
          base: "ef-modal__overlay -lightbox -inv",
          afterOpen: "ef-modal__overlay--after-open",
          beforeClose: "ef-modal__overlay--before-close",
        }}
        htmlOpenClassName="ef-modal--active"
        ariaHideApp={false}
        closeTimeoutMS={150}
        open={openModal}
      >
        <div className="modal__wrap">
          <div className="ef-container">
            <div className="modal__content">
              <div className="modal__player-video-wrap">
                <div
                  className="modal__player"
                  style={{ width: "100%", height: "100%" }}
                >
                  <ReactPlayer
                    url={url}
                    className="modal__player-video"
                    width="100%"
                    height="100%"
                    playing={true}
                    controls={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

interface PosterProps {
  alt?: string;
  filename: string;
  title?: any;
  aspectMobile?: ImageStoryblok["aspect_ratio_mobile"];
  aspectDesktop?: ImageStoryblok["aspect_ratio_desktop"];
  autoplay?: boolean;
  className?: string;
  centerButton?: boolean;
  smallVideoPlayButton?: boolean;
}

const Poster = (props: PosterProps) => {
  const {
    alt,
    filename,
    title,
    aspectMobile,
    aspectDesktop,
    autoplay,
    className,
  } = props;
  return (
    <>
      <Image
        alt={alt}
        aspectMobile={aspectMobile}
        aspectDesktop={aspectDesktop}
        filename={filename}
      />
      <div
        className={classnames(
          styles.videoOverlay,
          {
            [styles.centerVideoButton]: props.centerButton,
          },
          className
        )}
      >
        {title
          ? title.map((blok: any) =>
              createElement(Components(blok.component), {
                blok,
                key: blok._uid,
              })
            )
          : null}
        {!autoplay ? (
          <PlayButton size={props.smallVideoPlayButton ? "small" : "normal"} />
        ) : null}
      </div>
    </>
  );
};

const PlayButton = (props: { size?: "small" | "normal" }) => (
  <div
    className={classnames(styles.videoPlayButton, {
      [styles.smallVideoPlayButton]: props.size === "small",
    })}
  >
    <IconPlay />
  </div>
);

export default HeroVideo;
